import React, { useEffect, useState } from "react";
import { Container, Row, Col, Spinner } from "reactstrap"
import { Link } from "@reach/router";
import room from "../assets/room.jpg"
import breakline from "../assets/break.PNG";
import scones from "../assets/scones.jpg";
import "./app.css";
import {firestore} from "../firebase";
import moment from "moment";
import ldMenu from "../assets/wh-lockdown-nov.pdf"
import Christmas from "./Christmas/Chrtistmas";

import { FacebookProvider, Feed } from 'react-facebook';

const Home = () => {
    const [loadingEvents, setLoadevents] = useState(true);
    const [eventsArray, setEventsArray] = useState([]);
    const [lockdownArray, setLockdownArray] = useState();
    const [christmasArray, setChristmasArray] = useState();
    const [lockdown] = useState(true);
    const [christmas] = useState(false);




useEffect(() => {
    const fetchData = async() => {
        const lockdown = firestore.collection('lockdown')
        const doc2 = await lockdown.get().then((snapshot2)=> {
           const data2 = snapshot2.docs.map((doc2) => ({
             id: doc2.id,
             ...doc2.data(),
           }));
           setLockdownArray(data2[0].lockdown)
         })

         const christmas = firestore.collection('christmas')
         const doc3 = await christmas.get().then((snapshot3) => {
             const data3 = snapshot3.docs.map((doc3) => ({
                 id: doc3.id,
                 ...doc3.data()
             }))
             setChristmasArray(data3[0].christmas)
         })
                   
        const events =  firestore.collection('events')
        const doc = await events.where('active', '==', true). where('eventDate', '>', new Date()).orderBy('eventDate', 'desc').limit(3).get().then((snapshot) => {
            const data = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setEventsArray(data)
          }).then((snapshot) =>{
            setLoadevents(false)
          });
 
    }

    fetchData();

    },[])
    
    const lockDownAlert = () => {
        if(lockdownArray === true)
        return(
            <Row className="row-m-20">
                <Col md={12} className="col-m-b-20 remove-padding">
                    <div className="textContainer" style={{textAlign:"center"}}>
                        <p>Please <a style={{color:"black"}} href={ldMenu} target="_blank">CLICK HERE</a> to see our takeaway and delivery menu during lockdown</p>
                </div>
                </Col>
               
            </Row>
        )
    }

    const christmasAlert = () => {
        if(christmasArray === true){
            return(
               <div></div>
            )
        }
    }

    const createDate = (doc) => {
        if(typeof doc.eventToDate !== "undefined")
        {
            if(doc.eventToDate !== doc.eventDate)
            {
                return(
                    <div>{moment(doc.eventDate.seconds * 1000).format('DD-MM-YYYY')} To: {moment(doc.eventToDate.seconds * 1000).format('DD-MM-YYYY')}</div>
                )
            }
            else{
                return(
                    <div>{moment(doc.eventDate.seconds * 1000).format('DD-MM-YYYY')}</div>
                )
                    
                
            }
        }
        
    }


    const displayEvents = () => {
        if (loadingEvents) {
            return (
                <div style={{ textAlign: "center" }}>
                loading events...<br/><br/>
                <Spinner />
                </div>
            )
        }
        else{
            return eventsArray.map((doc,id) =>
            <div>
            <Row>
                <Col md={5}>
                   <div>
                   <b>Event:    </b>{doc.eventTitle}
                   </div>
                   </Col>
                   <Col md={4}>
        <b>Date:</b> {createDate(doc)}
                   </Col>
                   <Col md={3}>
                        <Link style={{fontWeight:"bold" }} className="detailsButton" to="events">See Details</Link>
                   </Col>
              </Row>
              <hr/>
              </div>
           )
        }
    }


    return (
        <Container fluid>
            {/* {lockDownAlert()} */}
            {christmasAlert()}
            {/* <Christmas/> */}
            <Row className="row-m-20">
                <Col md={6} className="col-m-b-20 remove-padding">
                    <div className="textContainer">
                        <p>Leek’s Oldest Tearoom, The White Hart is a Grade II listed building in very the centre of Leek, just opposite the historic Market Place. It is a beautiful building and has been lovingly restored to include cosy guest accommodation, a lovely fully licensed tearoom with a wide menu & outdoor courtyard along with a sandwich bar.</p>
                        <p>Our Accommodation features eight individual en-suite bedrooms, decorated to the highest standards and fully equipped with all of the facilities that you would expect for a cosy, comfy stay. Due to the historic nature of our building all rooms are accessed via stairs.</p>
                </div>
                </Col>
                <Col md={6} className="remove-padding">
                    <div>
                        <img className="image-wh shadow-image" src={room} />
                    </div>
                </Col>
            </Row>

            <Row className="row-m-20">
                <Col className="remove-padding">
                    <div>
                        <img src={breakline} className="center shadow-image" style={{ width: "98%", height: "50%", borderRadius: 5, opacity: 0.5 }} />
                    </div>
                </Col>
            </Row>
            <Row className="row-m-20">
                            <Col md={{size: 3, offset: 1}} className="remove-padding">
                <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fwhitehartleek&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=760248734337450" width="340" height="500" style={{border:"none",overflow:"hidden"}} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe> 
                </Col>
                <Col md={{size:5, offset:3}} className="remove-padding">
                    <div className="textContainer">
                        <h3 style={{ textAlign: "center", marginBottom:10, fontWeight:"bold" }}>Upcoming Events</h3>
                        <hr/>
                        <div>
                            {displayEvents()}
                            </div>
                    </div>
                    <div>
                        <img src={scones} className="image-wh shadow-image" style={{ maxHeight: 357, marginTop:10 }} />
                    </div>
                </Col>
            </Row>
            <Row>
            <Col md={6} className="col-m-b-20 remove-padding">
                    
                </Col>    
            </Row>
        </Container>
    )
}

export default Home;




